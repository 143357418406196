import UserInfo from './UserInfo';
import React, { useState, useEffect } from "react";
import Scanner from './Scanner';
import axios from "axios";
import Line from './Line';
import ThemeSwitcher from './ThemeSwitcher';
import BackgroundSound from './BackgroundSound';
import ClickSound from "./ClickSound";
import HoverSound from "./HoverSound";

const SideBar = () => {
  const [currentDate, setCurrentDate] = useState({
    time: "",
    date: "",
    day: "",
    month: "",
    year: "",
    browser: "",
  });
  const [uptime, setUptime] = useState(0); // Store uptime in seconds
  const [os, setOs] = useState(""); // Store the operating system

  useEffect(() => {
    const startTime = Date.now(); // Get the start time when the component mounts

    // Function to get current time, date, year, and browser
    const getCurrentInfo = () => {
      const now = new Date();
      const time = getFormattedTime(now); // Get current time in 24-hour format
      const { day, month, date, year } = getFormattedDate(now); // Get day, month, date, and year
      const browser = getBrowser(); // Get browser name
      const osType = getOs(); // Get OS type

      setCurrentDate({
        time,
        date,
        day,
        month,
        year,
        browser,
      });
      setOs(osType); // Set the OS type
    };

    // Function to detect the browser
    const getBrowser = () => {
      const userAgent = navigator.userAgent;

      const browserData = [
        { name: "Edge", criteria: ["EdgA", "Edg", "Edge"] },
        { name: "Opera", criteria: ["OPR", "Opera", "OPiOS"] },
        { name: "Brave", criteria: ["Brave"] },
        { name: "Chrome", criteria: ["Chrome", "CriOS"] },
        { name: "Firefox", criteria: ["Firefox", "FxiOS"] },
        { name: "DuckDuckGo", criteria: ["DuckDuckGo", "DuckDuckGo/"], exclude: ["Safari"] },  // Adjusted DuckDuckGo detection
        { name: "Safari", criteria: ["Safari"], exclude: ["Chrome", "CriOS", "FxiOS", "DuckDuckGo"] }, // Exclude DuckDuckGo
        { name: "Explorer", criteria: ["MSIE", "Trident"] },
        { name: "Vivaldi", criteria: ["Vivaldi"] },
        { name: "Yandex", criteria: ["YaBrowser"] },
        { name: "Epic", criteria: ["Epic"] },
        { name: "Tor", criteria: ["TorBrowser"] },
        { name: "Pale Moon", criteria: ["PaleMoon"] },
        { name: "Waterfox", criteria: ["Waterfox"] },
        { name: "Samsung int", criteria: ["SamsungBrowser"] }, // Samsung's custom browser
        { name: "Mi Browser", criteria: ["MiBrowser"] }, // Xiaomi browser
      ];

      // Check if DuckDuckGo specifically is present
      if (userAgent.includes("DuckDuckGo") || userAgent.includes("DuckDuckGo/")) {
        return "DuckDuckGo"; // If it's DuckDuckGo, return that
      }

      // Check for the rest of the browsers
      for (const browser of browserData) {
        if (
          browser.criteria.some((criterion) => userAgent.includes(criterion)) &&
          !(browser.exclude || []).some((exclusion) => userAgent.includes(exclusion))
        ) {
          return browser.name;
        }
      }

      return "Unknown";
    };

    // Function to detect the operating system
    const getOs = () => {
      const platform = navigator.platform;
      const userAgent = navigator.userAgent;

      const osData = [
        { name: "Windows", criteria: ["Win"] },
        { name: "MacOS", criteria: ["Mac"], exclude: ["iPhone", "iPad", "iPod"] }, // Exclude iOS devices
        { name: "iOS", criteria: ["iPhone", "iPad", "iPod"], exclude: ["Mac"] },  // Exclude macOS devices
        { name: "Android", criteria: ["Android"] },
        { name: "Kali Linux", criteria: ["Kali"] },
        { name: "Arch Linux", criteria: ["Arch"] },
        { name: "Ubuntu", criteria: ["Ubuntu"] },
        { name: "Fedora", criteria: ["Fedora"] },
        { name: "Debian", criteria: ["Debian"] },
        { name: "CentOS", criteria: ["CentOS"] },
        { name: "openSUSE", criteria: ["openSUSE"] },
        { name: "Linux", criteria: ["Linux"], exclude: ["Android"] },
      ];

      // Check if it's specifically iOS
      if (userAgent.includes("iPhone") || userAgent.includes("iPad") || userAgent.includes("iPod")) {
        return "iOS";
      }

      // Check for the rest of the operating systems
      for (const os of osData) {
        if (
          os.criteria.some((criterion) => userAgent.includes(criterion) || platform.includes(criterion)) &&
          !(os.exclude || []).some((exclusion) => userAgent.includes(exclusion) || platform.includes(exclusion))
        ) {
          return os.name;
        }
      }

      return "Unknown";
    };

    // Example usage
    console.log("Detected browser:", getBrowser());
    console.log("Detected operating system:", getOs());



    // Function to format time in 24-hour format
    const getFormattedTime = (date) => {
      const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }; // 24-hour format
      return date.toLocaleTimeString('en-US', options); // This will give the time in 24-hour format (e.g., 14:45:30)
    };

    // Function to format date and split it into day, month, date, and year
    const getFormattedDate = (date) => {
      const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);
      const [day, month, dayOfMonth, year] = formattedDate.split(' ');

      // Remove the comma after the date, if exists
      const cleanDate = dayOfMonth.replace(',', ''); // remove comma after date if it exists

      return { day, month, date: cleanDate, year };
    };

    // Update info every second
    const interval = setInterval(() => {
      getCurrentInfo();
      setUptime(Math.floor((Date.now() - startTime) / 1000)); // Calculate uptime in seconds
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Format uptime into HH:MM:SS format
  const formatUptime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };
  // IP addres atcher
  const [ip, setIP] = useState("");
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    // console.log(res.data);
    setIP(res.data.ip);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    
    <>
      <div className="md:mr-5 group/line border lg:h-full lg:relative border-primary-2 relative  mb-6 md:mb-0 md:max-w-64 md:min-w-64 w-full flex flex-col items-center md:items-start md:justify-start">
        <div className="flex">
          <Line />
        </div>
        <div className="text-primary-7 flex flex-col w-full">
          <div className="w-full px-2 md:px-3  justify-between md:justify-start border-b border-b-primary-2 min-h-10 h-10 flex items-center w-full">
            <p className="text-xl">{currentDate.time}</p>
            <div className="flex md:ml-auto">
              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <BackgroundSound />
              </ClickSound>
            </div>
            <div className="flex md:hidden items-center md:ml-auto relative">
              <span className="text-primary-7 md:text-primary-4 text-min mt-[0.2rem] mr-2 whitespace-nowrap">Mode-></span>
              <div className="flex  gap-2 md:gap-0 not-hover-opacity">
                <ThemeSwitcher />
              </div>
            </div>
          </div>
          <div className="flex uppercase leading-[.93rem] justify-between px-2 md:px-3 text-sm w-full border-b border-b-primary-2 min-h-10 h-10 flex items-center w-full">
            <div className="flex">
              <div className="">
                <p className="opacity-75">{currentDate.year}</p>
                <div className="flex">
                  <p className="mr-1">{currentDate.month}</p>
                  <p>{currentDate.date}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <p className="opacity-75">Browser</p>
                <div className="flex">
                  <p>{currentDate.browser}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <p className="opacity-75">Uptime</p>
                <div className="flex">
                  <p>{formatUptime(uptime)}</p>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="">
                <p className="opacity-75">Device</p>
                <div className="flex">
                  <p>{os}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full border-b border-b-primary-2">
            <HoverSound hoverSoundSrc="/sounds/process.mp3">
              <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <Scanner />
              </ClickSound>
            </HoverSound>
          </div>
          <div className="flex text-min w-full px-3 md:justify-start border-b border-b-primary-2 min-h-10 h-10 flex items-center w-full">
            <h5 className="text-sm text-primary-7 ">YOUR IP - {ip}</h5>
          </div>
        </div>
        <div className="flex flex-col w-full h-full">
          <div className="px-3 py-3 flex flex-col  h-full tracking-wider">
            <UserInfo />
          </div>
          <div className="w-full flex justify-end duration-150 transition opacity-[.36] pl-24 group-hover/line:opacity-[.63]">
            <svg className="w-full h-full max-w-[16rem]" viewBox="0 0 433 129" fill="none">
              <g clipPath="url(#clip0_324_2)">
                <path d="M26 65.72H225.828L241.317 81.209H309.787L363.04 136.999" className='stroke-primary-7' strokeWidth="4" />
                <path d="M14 78C20.6274 78 26 72.6274 26 66C26 59.3726 20.6274 54 14 54C7.37258 54 2 59.3726 2 66C2 72.6274 7.37258 78 14 78Z" className='stroke-primary-7' strokeWidth="4" />
                <path d="M150.369 78.0491H221.59L236.541 93.0001" className='stroke-primary-7' strokeWidth="2" />
                <path d="M149.5 83C151.985 83 154 80.9853 154 78.5C154 76.0147 151.985 74 149.5 74C147.015 74 145 76.0147 145 78.5C145 80.9853 147.015 83 149.5 83Z" className='fill-primary-7' />
                <path d="M193.5 58C195.985 58 198 55.9853 198 53.5C198 51.0147 195.985 49 193.5 49C191.015 49 189 51.0147 189 53.5C189 55.9853 191.015 58 193.5 58Z" className='fill-primary-7' />
                <path d="M239.5 101C241.985 101 244 98.9853 244 96.5C244 94.0147 241.985 92 239.5 92C237.015 92 235 94.0147 235 96.5C235 98.9853 237.015 101 239.5 101Z" className='stroke-primary-7' strokeWidth="3" />
                <path d="M426.5 21C428.985 21 431 18.9853 431 16.5C431 14.0147 428.985 12 426.5 12C424.015 12 422 14.0147 422 16.5C422 18.9853 424.015 21 426.5 21Z" className='stroke-primary-7' strokeWidth="3" />
                <path d="M194.52 54.0001H282.24L297.772 38.4681H405.929L425.139 19.2581" className='stroke-primary-7' strokeWidth="2" />
              </g>
              <defs>
                <clipPath id="clip0_324_2">
                  <rect className='w-full h-full' fill="white" transform="matrix(1 0 0 -1 0 129)" />
                </clipPath>
              </defs>
            </svg>

          </div>
        </div>
      </div>
    </>
  );
};
export default SideBar;
