import { useEffect, useState } from "react";
import Line from "./Line";
import HoverSound from "./HoverSound";

function Works() {
  const [workData, setWorkData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/data.json");
      const data = await response.json();
      setWorkData(data);
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col p-2 md:p-5 tracking-wider">
      {workData.map((work) => (
        <HoverSound
          key={work.id} // გამოიყენეთ id როგორც უნიკალური key
          hoverSoundSrc="/sounds/process.mp3"
          className={`px-2.5 py-2 md:px-5 md:py-4 border border-primary-2 relative transition duration-150 group/works md:hover:shadow-type-1 ${
            work.id === workData[workData.length - 1].id ? "" : "mb-2 md:mb-5"
          }`}
        >
          <div className="hidden group-hover/works:flex">
            <Line />
          </div>
          <div className="flex justify-between items-center mb-1">
            <h4 className="text-md text-primary-4 uppercase">{work.role}</h4>
            <p className="text-md text-primary-3 group-hover/works:text-primary-7">
              {work.period}
            </p>
          </div>
          <h5 className="text-sm text-primary-4 mb-1">{work.company}</h5>
          <p className="text-xs text-primary-3 mb-1">{work.location}</p>
          <p className="text-xs text-primary-3 mb-1">{work.description}</p>
          <div className="flex">
            {work.links.map((link, index) => (
              <span key={link.url} className="flex">
                <a
                  className="text-xs text-primary-7 hover:text-primary-5 text-ellipsis overflow-hidden"
                  target="_blank"
                  rel="noreferrer"
                  href={link.url}
                >
                  {link.text}
                </a>
                {index < work.links.length - 1 && <span className="mx-1 text-xs text-primary-3">&</span>}
              </span>
            ))}
          </div>
        </HoverSound>
      ))}
    </div>
  );
}

export default Works;
