import React, { useEffect, useRef, useState } from "react";

const CustomCursor = () => {
  const [cursorType, setCursorType] = useState("default");
  const cursorRef = useRef(null);

  useEffect(() => {
    const cursor = cursorRef.current;

    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      if (cursor) {
        cursor.style.transform = `translate(${clientX - 20}px, ${clientY - 20}px)`;
      }
    };
    // const handleMouseEnter = () => {
    //   if (cursor) {
    //     cursor.style.transform = "scale(1.5)";
    //   }
    // };

    // const handleMouseLeave = () => {
    //   if (cursor) {
    //     cursor.style.transform = "scale(1)";
    //   }
    // };

    const handleCursorTypeChange = (type) => {
      setCursorType(type);
    };

    // Add event listeners
    document.addEventListener("mousemove", handleMouseMove);

    document.querySelectorAll("a, button, .cursor-pointer").forEach((el) => {
      el.addEventListener("mouseenter", () => handleCursorTypeChange("pointer"));
      el.addEventListener("mouseleave", () => handleCursorTypeChange("default"));
    });

    document.body.style.cursor = "none";

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.querySelectorAll("a, button, .cursor-pointer").forEach((el) => {
        el.removeEventListener("mouseenter", () =>
          handleCursorTypeChange("pointer")
        );
        el.removeEventListener("mouseleave", () =>
          handleCursorTypeChange("default")
        );
      });
      document.body.style.cursor = "none";
    };
  }, []);

  return (
    <>
      <div
        ref={cursorRef}
        className={`w-10 h-10 bg-no-repeat before:absolute be bg-[8px_8px] flex pointer fixed z-9 pointer-events-none flex justify-center items-center
          ${cursorType === "pointer" ? "bg-[length:30px_30px] bg-[0px_5px]" : " bg-[length:22px_22px]"
          }`}
      >
        {cursorType === "pointer" ? (
          <svg className="absolute w-6 top-[16px] left-[12px]"
            viewBox="0,0,256,256">
            <g transform="scale(3.55556,3.55556)">
              <path className="fill-mouse-primary" d="M36,41c-0.552,0 -1,0.448 -1,1c0,0.186 0,7.814 0,8c0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1c0,-0.186 0,-7.814 0,-8c0,-0.552 -0.448,-1 -1,-1zM41,41c-0.552,0 -1,0.448 -1,1c0,0.186 0,7.814 0,8c0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1c0,-0.186 0,-7.814 0,-8c0,-0.552 -0.448,-1 -1,-1zM46,41c-0.552,0 -1,0.448 -1,1c0,0.186 0,7.814 0,8c0,0.552 0.448,1 1,1c0.552,0 1,-0.448 1,-1c0,-0.186 0,-7.814 0,-8c0,-0.552 -0.448,-1 -1,-1zM57.506,26.099c2.715,0.905 3.494,2.63 3.494,5.705c0,0.294 -0.005,4.756 -0.005,5.717c0,12.368 -6.518,12.607 -6.518,19.071c0,1.714 0.127,3.426 -0.25,4.008c-0.377,0.582 -0.685,1.4 -2.444,1.4c-0.136,0 -4.632,0 -4.782,0c-3.221,0 -4.983,-2.44 -4.983,-2.44c0,0 -2.212,2.44 -4.38,2.44c-1.006,0 -6.195,0 -7.828,0c-0.396,0 -1.939,-0.444 -2.527,-1.402c-0.974,-1.585 -0.161,-3.034 -0.974,-4.79c-0.916,-1.978 -5.546,-4.202 -8.121,-11.226c-2.734,-7.455 -6.61,-6.977 -6.61,-10.77c0,-3.768 2.77,-5.338 4.644,-5.338c4.87,0 7.586,3.465 7.586,3.465c0,0 -3.452,-10.102 -4.073,-12.23c-1.287,-4.404 -0.016,-8.35 3.98,-9.217c4.594,-0.997 6.618,2.786 7.332,4.703c0.826,2.219 3.251,8.56 3.251,8.56c0,0 2.652,-1.214 4.884,-0.74c2.422,0.514 3.391,1.856 3.391,1.856c0,0 2.147,-1.887 5.672,-1.23c3.323,0.62 4.55,3.164 4.55,3.164c0,0 2.537,-1.431 4.711,-0.706z">
              </path>
            </g>
          </svg>
        ) : (
          <svg
            className="absolute w-4 top-[20px] left-[19px]"
            viewBox="0 0 14 14">
            <path className="fill-mouse-primary" d="M1.972.08H1.97A1.49 1.49 0 0 0 .08 1.97l.473-.162l-.473.163l.001.002l.002.006l.008.024l.032.092l.12.35L.675 3.7a30731.61 30731.61 0 0 1 3.07 8.944l.128.373l.035.104l.01.03a.995.995 0 0 0 1.73.56a.989.989 0 0 0 .193-.26c.045-.088.078-.187.11-.279l.001-.004l.995-2.87l.65-1.876l.213-.613l.002-.005l.007-.002l.617-.218l1.885-.667a322.95 322.95 0 0 0 2.878-1.027l.046-.018c.11-.043.307-.118.465-.276a.994.994 0 0 0 0-1.406l-.01-.01a1.276 1.276 0 0 0-.182-.164a1.221 1.221 0 0 0-.373-.169h-.002l-.035-.012L13 3.8l-.382-.127l-1.298-.436A5548.437 5548.437 0 0 1 3.687.66L2.441.24L2.093.12L2 .091l-.023-.01z"
            />
          </svg>
        )}
      </div>
    </>
  );
};

export default CustomCursor;
