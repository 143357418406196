function Footer() {
  return (
    <>
      <div className="w-full flex md:min-h-7 min-h-20 h-20 md:h-7 md:min-h-7 xl:h-9 xl:min-h-9 px-10 items-center justify-center">
      </div>
    </>
  );
}

export default Footer;
