import Skills from './Skills';
import Certificates from './Certificates';
import About from './About';
import Works from './Works';
import Line from './Line';
import ThemeSwitcher from './ThemeSwitcher';
function Content() {
  return (
    <>
      <div className="flex flex-wrap w-full justify-center h-full relative ">
        <div className="hidden lg:flex">
          <Line />
        </div>
        <div className="flex flex-col w-full lg:w-3/6 border border-primary-2 relative lg:h-full flex  mb-6 md:mb-5 group/treangle">
          <div className="flex lg:hidden">
            <Line />
          </div>
          <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-3 pr-2 md:px-5 md:pr-4">
          <h5 className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">to collaborate on • UI/AI  </h5>
            <div className="flex pl-5 lg:hidden items-center ml-auto relative">
              <span className="relative md:mr-2 flex h-2 w-2 flex items-center justify-center">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-7 opacity-90"></span>
                <span className="relative inline-flex rounded-full h-1 w-1 bg-primary-7 opacity-75"></span>
              </span>
             <div className="hidden md:flex items-center">
             <span className="text-primary-7 md:text-primary-4 hidden md:flex text-min mt-1 whitespace-nowrap">Mode-></span>
              <div className="flex  gap-2 md:gap-0 not-hover-opacity">
                <ThemeSwitcher />
              </div>
             </div>
            </div>
          </div>
          <div className="p-2 md:p-5 h-full overflow-y-auto flex flex-col">
            <Skills />
            {/* <Certificates /> */}
            <About />
          </div>
          <div className="border-t border-t-primary-2 min-h-10 h-10 flex items-center pl-4 mt-auto justify-center lg:justify-start pr-4">
            <svg
              viewBox="0 0 134 117.208"
              className="fill-primary-7 duration-150 transition group-hover/treangle:opacity-1 transform opacity-[0.39] rotate-180 w-5"
            >
              <path
                fill=""
                d="m0 0 57.167 99.667L94 36.333H73.167l5.041 8.75L57.667 82l-47-82z"
              ></path>
              <path
                fill=""
                d="m20.833 0 36.834 63.416L68.25 45.083H57.167L36.583 9.25h93.75L135.834 0z"
              ></path>
              <path
                fill=""
                d="M67.834 27.167 62.75 36 52.5 18.25h72.521L68 117.208l-5.375-8.625 46.75-81.5z"
              ></path>
            </svg>
          </div>
        </div>

        <div className="flex flex-col w-full lg:w-3/6 border border-primary-2 lg:border-l-transparent relative lg:h-full flex flex-col group/treangle">
          <div className="flex lg:hidden">
            <Line />
          </div>
          <div className="border-b border-b-primary-2 min-h-10 h-10 flex items-center px-3 pr-2 nd:px-5">
            <h5 className="xl:text-md lg:text-sm text-sm whitespace-nowrap  text-primary-4 uppercase">Work HISTORY • 2015 - now</h5>
            <div className="flex md:hidden lg:flex items-center ml-auto relative">
              <span className="relative md:mr-2 flex h-2 w-2 flex items-center justify-center">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-7 opacity-90"></span>
                <span className="relative inline-flex rounded-full h-1 w-1 bg-primary-7 opacity-75"></span>
              </span>
            <div className="hidden md:flex items-center">
            <span className="text-primary-7 md:text-primary-4 text-min mt-1 lg:flex hidden whitespace-nowrap">Mode-></span>
              <div className="flex  gap-2 md:gap-0 not-hover-opacity">
                <ThemeSwitcher />
              </div>
            </div>

            </div>
          </div>
          <div className="flex flex-col overflow-y-auto">
            <Works />
          </div>
          <div className="border-t border-t-primary-2 min-h-10 h-10 flex items-center justify-center lg:justify-end px-4 w-full mt-auto">
            <svg
              viewBox="0 0 134 117.208"
              className="fill-primary-7 duration-150 transition group-hover/treangle:opacity-1 transform opacity-[0.39] rotate-180 w-5"
            >
              <path
                fill=""
                d="m0 0 57.167 99.667L94 36.333H73.167l5.041 8.75L57.667 82l-47-82z"
              ></path>
              <path
                fill=""
                d="m20.833 0 36.834 63.416L68.25 45.083H57.167L36.583 9.25h93.75L135.834 0z"
              ></path>
              <path
                fill=""
                d="M67.834 27.167 62.75 36 52.5 18.25h72.521L68 117.208l-5.375-8.625 46.75-81.5z"
              ></path>
            </svg>
          </div>
        </div>
      </div>

    </>
  );
}

export default Content;
