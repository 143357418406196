import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

document.body.className = "bg-base flex flex-col relative before:absolute before:top-0 before:left-0 before:w-full before:h-full before:content-[''] before:opacity-zero-3 before:z-2 before:pointer-events-none  before:bg-[url('./images/static_tv.gif')] after:absolute after:top-0 after:left-0 after:w-full after:h-full after:content-[''] after:opacity-zero-3 after:z-1 after:pointer-events-none after:bg-primary-7";
document.getElementById('root').className = "font-display font-bold flex flex-col lg:h-screen relative w-full";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
