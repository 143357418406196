
import { useTheme } from './ThemeContext';
import HoverSound from "./HoverSound";
import ClickSound from "./ClickSound";

function ThemeSwitcher() {
    const { toggleTheme } = useTheme();
    return (
        <>

            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <button
                    onClick={() => toggleTheme('primary-1')}
                    className=" flex"
                >
                    <HoverSound hoverSoundSrc="/sounds/process.mp3" className="flex md:p-1">
                        <span className="w-2.5 h-2.5 bg-[#22c55e]"></span>
                    </HoverSound>
                </button>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <button
                    onClick={() => toggleTheme('primary-2')}
                    className="flex"
                >
                    <HoverSound hoverSoundSrc="/sounds/process.mp3" className="flex md:p-1">
                        <span className="w-2.5 h-2.5 bg-[#eab308]"></span>
                    </HoverSound>
                </button>
            </ClickSound>
            <ClickSound clickSoundSrc="/sounds/ui-click.mp3">
                <button
                    onClick={() => toggleTheme('primary-3')}
                    className="flex"
                >
                    <HoverSound hoverSoundSrc="/sounds/process.mp3" className="flex md:p-1">
                        <span className="w-2.5 h-2.5 bg-[#f43f5e]"></span>
                    </HoverSound>
                </button>
            </ClickSound>
        </>
    );
}
export default ThemeSwitcher;
