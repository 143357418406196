import Eye from './Eye';

function Header() {
  return (
    <>
      <div className="w-full gap-1 flex min-h-2 h-2 md:min-h-7 md:h-7 xl:min-h-9 xl:h-9 px-10 justify-center items-center flex">
        <Eye />
        <Eye />
      </div>
    </>
  );
}

export default Header;
